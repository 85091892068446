import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

function createMessage(text) {
  const alert = document.createElement('div')
  alert.className = 'alert'
  alert.innerText = text

  document.body.appendChild(alert)

  gsap.fromTo(alert, 0.8, {
    opacity: 0,
    y: '-100%',
  }, {
    ease: 'power3.out',
    opacity: 1,
    y: 0,
    onComplete: () => {
      gsap.to(alert, 0.8, {
        delay: 3,
        ease: 'power3.out',
        opacity: 0,
        y: '-100%',
        onComplete: () => {
          document.body.removeChild(alert)
        }
      })
    }
  })
}

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    if (successful) {
      // Fallback was successful
      createMessage('De link is gekopieerd naar het klembord.')
    } else {
      // Fallback was unsuccessful
      createMessage('Het kopiëren van de link is mislukt. Probeer het in een andere browser nog een keer.')
    }
  } catch (err) {
    createMessage('Het kopiëren van de link is mislukt. Probeer het in een andere browser nog een keer.')
  }

  document.body.removeChild(textArea)
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }

  navigator.clipboard.writeText(text)
    .then(function () {
      createMessage('De link is gekopieerd naar het klembord.')
    }, function (err) {
      console.log('Error', err)
      createMessage('Het kopiëren van de link is mislukt. Probeer het in een andere browser nog een keer.')
    })
}

docReady(() => {
  const copyButtons = document.querySelectorAll('.copy-button')
  if (copyButtons.length) {
    for (let i = 0; i < copyButtons.length; i++) {
      copyButtons[i].addEventListener('click', () => {
        const url = copyButtons[i].getAttribute('data-url')
        copyTextToClipboard(url)
      })
    }
  }
})
