import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.watch-slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      watchCSS: true,
      autoPlay: 3000,
      draggable: false,
      accessibility: false
    })
  })

  Array.from(document.querySelectorAll('.l-gallery .l-gallery__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      adaptiveHeight: true
    })
  })

  Array.from(document.querySelectorAll('.l-testimonials__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      pageDots: false,
      adaptiveHeight: true
    })
  })

  Array.from(document.querySelectorAll('.l-text-slides__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      pageDots: false,
      percentPosition: false,
      wrapAround: false,
      groupCells: true
    })
  })

  Array.from(document.querySelectorAll('.l-years__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      adaptiveHeight: true
    })
  })
})
