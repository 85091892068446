import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'
import gsap from 'gsap'

const startSlide = index => {
  const el = document.querySelectorAll('.hero-slide')[index]
  if (el) {
    const tl = gsap.timeline()
    const bg = el.querySelector('.hero-slide__image--bg')
    const fg = el.querySelector('.hero-slide__image--fg')
    // const content = el.querySelector('.hero-slide__col--content')

    tl.set([bg, fg], {
      opacity: 0
    }, 0)

    tl.fromTo(bg, 0.5, {
      scale: 0.6,
      y: '+=100px'
    }, {
      ease: 'power3.out',
      opacity: 1,
      scale: 1,
      y: '-50%'
    }, 0.5)

    tl.fromTo(fg, 0.8, {
      scale: 1
    }, {
      ease: 'power3.inOut',
      opacity: 1
    }, 0.9)

    tl.to(fg, 10, {
      ease: 'expo.out',
      scale: 1.2
    }, 0.9)

    // tl.fromTo(content, 0.5, {
    //   y: 50
    // }, {
    //   ease: 'power3.out',
    //   opacity: 1,
    //   y: 0
    // }, 0.5)
  }
}

docReady(() => {
  const heroSlider = document.querySelector('.l-hero')
  const heroSliderParams = {
    percentPosition: true,
    autoPlay: 4800,
    wrapAround: true,
    draggable: false,
    accessibility: false,
    on: {
      ready: () => {
        startSlide(0)
      },
      change: (index) => {
        startSlide(index)
      }
    }
  }

  if (heroSlider) {
    createSlider(heroSlider, heroSliderParams)
  }
})
