import docReady from '@/js/helpers/doc-ready'
import debounce from '@/js/helpers/debounce'
import { getPosts } from './ajax'

let isActive = false
const cb = () => {
  isActive = false
}

class SearchOverlay {
  constructor() {
    this.overlay = document.getElementById('search-overlay')
    this.form = document.getElementById('search-form')
    this.search = document.getElementById('search')
    this.results = document.getElementById('search-results')
    this.togglers = document.querySelectorAll('[data-toggle-search]')

    this.initEvents()
  }

  toggleOverlay() {
    this.overlay.classList.toggle('search-overlay--active')
    document.body.classList.toggle('search-active')
  }

  searchFor(s) {
    if (!isActive) {
      getPosts({
        action: 'search_in_all',
        s
      }, this.results, cb)
    }
  }

  submitForm(e) {
    e.preventDefault()
    this.searchFor(this.search.value)
  }

  initEvents() {
    const clickHandler = () => this.toggleOverlay()
    Array.from(this.togglers).forEach(el => {
      el.addEventListener('click', clickHandler)
    })

    const submitHandler = e => this.submitForm(e)

    const inputHandler = debounce((e) => this.searchFor(e.target.value), 200)

    this.search.addEventListener('input', inputHandler)
    this.search.addEventListener('paste', inputHandler)
    this.search.addEventListener('keyup', inputHandler)

    this.form.addEventListener('submit', submitHandler)
  }
}

docReady(() => {
  // eslint-disable-next-line
  const search = new SearchOverlay()
})
