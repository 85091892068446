import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

window.Element.prototype.remove = function () {
  this.parentElement.removeChild(this)
}

window.NodeList.prototype.remove = window.HTMLCollection.prototype.remove = function () {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i])
    }
  }
}

docReady(() => {
  const isIntroShown = window.sessionStorage.getItem('intro')
  const splash = document.getElementById('splash')

  if (splash) {
    const main = splash.querySelector('.splash__main')
    const after = splash.querySelector('.splash__after')

    if (isIntroShown) {
      // Hide splash
      splash.remove()
    }

    if (splash && !isIntroShown) {
      window.sessionStorage.setItem('intro', true)

      const tl = gsap.timeline()
      const b = splash.querySelector('.splash__b')
      const text = splash.querySelector('.splash__text')
      const layoutContainers = document.querySelectorAll('.l .container')
      const wrapper = document.querySelector('.wrapper')

      if (wrapper) {
        tl.set(wrapper, {
          overflow: 'hidden'
        }, 0)
      }

      // Hide all the containers
      if (layoutContainers.length) {
        tl.set(layoutContainers, {
          x: 50,
          opacity: 0
        }, 0)
      }

      tl.to(b, 0.3, {
        ease: 'power3.inOut',
        opacity: 1,
        scale: 1.2
      }, 0.1)

      tl.to(b, 0.2, {
        ease: 'power3.inOut',
        scale: 1
      }, 0.6)

      tl.to(b, 0.25, {
        ease: 'power3.out',
        x: 0
      }, 1)

      tl.to(text, 0.25, {
        ease: 'power3.out',
        opacity: 1,
        x: 0
      }, 1)

      const wh = window.innerHeight

      tl.to([main, after], 0.5, {
        ease: 'power1.inOut',
        stagger: 0.1,
        borderTopRightRadius: wh / 2,
        borderBottomRightRadius: wh / 2
      }, 1.3)

      // Hide splash
      tl.to([main, after], 0.3, {
        ease: 'power2.inOut',
        x: '-100%',
        stagger: 0.2,
        onComplete: () => {
          splash.remove()
        }
      }, 1.8)

      // Show containers
      if (layoutContainers.length) {
        tl.to(layoutContainers, 0.2, {
          ease: 'power3.out',
          opacity: 1,
          x: 0,
          onComplete: () => {
            tl.set(layoutContainers, {
              clearProps: 'all'
            })

            if (wrapper) {
              tl.set(wrapper, {
                clearProps: 'overflow'
              })
            }
          }
        }, 2.1)
      }
    }
  }
})
